import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { scroller } from 'react-scroll'
import { SCROLL_OPTION } from '@/lib/scroll'

export function useInitialScroll(): void {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (
      url: string,
      { shallow }: { shallow: boolean },
    ) => {
      if (!shallow) {
        const splitedUrl = url.split('#')

        if (splitedUrl.length < 2) return

        const hash = splitedUrl[splitedUrl.length - 1]

        const timer = setTimeout(() => {
          scroller.scrollTo(hash, SCROLL_OPTION)
        }, 444)

        return () => clearTimeout(timer)
      }
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])
}
