import Checkbox from './checkbox'

const parts = ['container', 'control', 'label']

const baseStyle = (props: Dict) => ({
  label: Checkbox.baseStyle(props).label,
})

const sizes = {
  sm: {
    control: { w: 4, h: 4 },
    label: { fontSize: 'sm' },
  },
  md: {
    control: { w: 5, h: 5 },
    label: { fontSize: 'md' },
  },
  lg: {
    control: { w: 6, h: 6 },
    label: { fontSize: 'lg' },
  },
}

const defaultProps = {
  size: 'md',
  colorScheme: 'blue',
}

export default {
  parts,
  baseStyle,
  sizes,
  defaultProps,
}
