export const CONTENT_MAX_WIDTH_MEDIUM = 1124
export const CONTENT_MAX_WIDTH_SMALL = 680

const layerStyles = {
  actionable: {
    opacity: 1,
    cursor: 'pointer',
    transition: 'opacity 150ms',
    '&:hover': {
      opacity: 0.8,
    },
    '&:focus': {
      opacity: 0.65,
    },
    '&:active': {
      opacity: 0.5,
    },
  },
  disabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  container: {
    minW: '320px',
    maxW: {
      base: '100%',
      sm: `${CONTENT_MAX_WIDTH_SMALL}px`,
      md: `${CONTENT_MAX_WIDTH_MEDIUM}px`,
    },
    mx: 'auto',
    pt: {
      base: 24,
      md: 24,
    },
    pb: {
      base: 16,
      md: 24,
    },
    px: {
      base: 6,
      sm: 8,
      md: 0,
      lg: 0,
    },
    color: 'gray.900',
    alignItems: 'stretch',
    w: 'full',
  },
  landingSectionHeading: {
    pb: {
      base: 4,
      sm: 8,
    },
  },
  fullPageBackgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    w: 'full',
    h: 'full',
    zIndex: -1,
  },
}

export default layerStyles
