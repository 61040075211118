import { ReactNode, ReactElement } from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

import { defaultTheme, jpTheme } from './chakraTheme'
import GlobalStyle from './GlobalStyle'

interface ThemeProviderProps {
  children: ReactNode
}

function AppThemeProvider(props: ThemeProviderProps): ReactElement {
  const {
    i18n: { language },
  } = useTranslation()

  return (
    <ChakraProvider resetCSS theme={language === 'ja' ? jpTheme : defaultTheme}>
      <GlobalStyle />
      {props.children}
    </ChakraProvider>
  )
}

export default AppThemeProvider
