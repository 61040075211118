import Button from './button'
import Link from './link'
import Input from './input'
import Select from './select'
import Textarea from './textarea'
import Checkbox from './checkbox'
import FormLabel from './form-label'
import List from './list'
import Radio from './radio'

export default {
  Button,
  Link,
  Input,
  Select,
  Textarea,
  Checkbox,
  FormLabel,
  List,
  Radio,
}
