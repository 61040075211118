import { Fragment } from 'react'
import { AppProps } from 'next/app'
import { DefaultSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { useTranslation, appWithTranslation } from 'next-i18next'

import ThemeProvider from '@/styles/ThemeProvider'
import { useInitialScroll } from '@/lib/hooks/useInitialScroll'
import { ProductionScripts } from '@/components/Scripts'

import env from '@/lib/env'
import { removeQueryStringAndHash } from '@/lib/utils/format/url'
import { useGTMPageview, UTMContextProvider } from '@/lib/gtm'

const useMeta = (): PageMeta => {
  const { t } = useTranslation('common')

  return {
    title: t('meta.title'),
    description: t('meta.description'),
    og: {
      title: t('meta.og.title'),
      description: t('meta.og.description'),
      image: {
        url: `${env.APP_URL}${t('meta.og.image.url')}`,
      },
    },
  }
}

function MyApp(props: AppProps): React.ReactElement {
  const { pageProps, Component } = props

  const {
    i18n: { language },
  } = useTranslation()

  const router = useRouter()

  useInitialScroll()
  useGTMPageview()

  const META = useMeta()

  const asPathWithoutQuery = removeQueryStringAndHash(router.asPath)

  return (
    <Fragment>
      <ProductionScripts />
      <DefaultSeo
        title={META.title}
        titleTemplate="%s | PDPA Core"
        description={META.description}
        canonical={`${env.APP_URL}/${language}${asPathWithoutQuery}`}
        openGraph={{
          type: 'website',
          url: `${env.APP_URL}${asPathWithoutQuery}`,
          title: META.og.title,
          description: META.og.description,
          site_name: 'PDPA Core',
          images: [
            {
              url: META.og.image.url,
              width: 1200,
              height: 630,
              alt: META.og.title,
            },
          ],
        }}
      />
      <UTMContextProvider>
        <ThemeProvider>
          <Component {...pageProps} />
        </ThemeProvider>
      </UTMContextProvider>
    </Fragment>
  )
}

export default appWithTranslation(MyApp)
