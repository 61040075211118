import { createBreakpoints } from '@chakra-ui/theme-tools'

const breakpoints = {
  xs: '375px',
  sm: '768px',
  md: '1280px',
  lg: '1680px',
  xl: '1920px',
  '2xl': '2560px',
}

const style = {
  breakpoints: createBreakpoints(breakpoints),
  sizes: {
    11: '2.75rem',
    15: '3.75rem',
    18: '4.5rem',
  },
  space: {
    11: '2.75rem',
    15: '3.75rem',
    18: '4.5rem',
  },
  fontSizes: {
    '2.7xl': '1.7rem',
    '3xl': '1.75rem',
    '3.5xl': '2rem',
    '3.75xl': '2.25rem',
    '4xl': '2.5rem',
    '5.5xl': '3.25rem',
  },
  radii: {
    '4xl': '2rem',
  },
  fonts: {
    body: `Anakotmai, Kanit, -apple-system, BlinkMacSystemFont,
              'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
              'Segoe UI Emoji', 'Segoe UI Symbol'`,
    heading: `Anakotmai, Kanit, -apple-system, BlinkMacSystemFont,
              'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
              'Segoe UI Emoji', 'Segoe UI Symbol'`,
    mono: `Anakotmai, Kanit, -apple-system, BlinkMacSystemFont,
              'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
              'Segoe UI Emoji', 'Segoe UI Symbol'`,
    blog: `sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
  },
}

export default style
