const parts = ['container', 'item', 'icon']

const baseStyleContainer = {}

const baseStyleItem = {}

const baseStyleIcon = {
  marginEnd: '0.5rem',
  display: 'inline',
  verticalAlign: 'text-bottom',
}

const baseStyle = {
  container: baseStyleContainer,
  item: baseStyleItem,
  icon: baseStyleIcon,
}

export default {
  parts,
  baseStyle,
}
