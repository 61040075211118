import init from '@/lib/utils/_init'

const circleBranch = process.env.CIRCLE_BRANCH

const envObj = {
  API_URL: process.env.API_URL || '',
  APP_URL: process.env.APP_URL || 'http://localhost:8081',

  PDPA_BLOG_API_URL: process.env.PDPA_BLOG_API_URL || '',
  PDPA_BLOG_API_KEY: process.env.PDPA_BLOG_API_KEY || '',

  CONSENT_WOW_API_URL: process.env.CONSENT_WOW_API_URL || '',

  CONSENT_WOW_QUOTATION_API_KEY: process.env.CONSENT_WOW_QUOTATION_API_KEY || '',
  CONSENT_WOW_QUOTATION_MARKETING_CONSENT_PURPOSE_ID: process.env.CONSENT_WOW_QUOTATION_MARKETING_CONSENT_PURPOSE_ID || '',

  CONSENT_WOW_RESOURCE_API_KEY: process.env.CONSENT_WOW_RESOURCE_API_KEY || '',
  CONSENT_WOW_RESOURCE_MARKETING_CONSENT_PURPOSE_ID: process.env.CONSENT_WOW_RESOURCE_MARKETING_CONSENT_PURPOSE_ID || '',

  CONSENT_WOW_OTHER_SERVICE_API_KEY: process.env.CONSENT_WOW_OTHER_SERVICE_API_KEY || '',
  CONSENT_WOW_OTHER_SERVICE_MARKETING_CONSENT_PURPOSE_ID: process.env.CONSENT_WOW_OTHER_SERVICE_MARKETING_CONSENT_PURPOSE_ID || '',

  EMAIL_RECIPIENTS: process.env.EMAIL_RECIPEINTS || '',
  SLACK_WEBHOOK_URL: process.env.SLACK_WEBHOOK_URL || '',

  GTM_CONTAINER_ID: '',
  COOKIE_BANNER_ID: '',

  IS_DEV: process.env.NODE_ENV === 'development',
  IS_PROD: false,
}

if (circleBranch === 'production') {
  envObj.GTM_CONTAINER_ID = process.env.GTM_CONTAINER_ID || ''
  envObj.COOKIE_BANNER_ID = process.env.COOKIE_BANNER_ID || ''
  envObj.IS_PROD = true
}

init(envObj.API_URL)

export default { ...envObj }
