/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import { Fragment } from 'react'
import Script from 'next/script'

import env from '@/lib/env'

export function ProductionScripts(): React.ReactElement | null {
  if (!env.IS_PROD) return null

  return (
    <Fragment>
      <Script id="gtm" strategy="afterInteractive">
        {`(function(w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', '${env.GTM_CONTAINER_ID}');`}
      </Script>
      <Script
        strategy="beforeInteractive"
        type="text/javascript"
        src="https://cookiecdn.com/cwc.js"
        defer={false}
      />
      <Script
        strategy="beforeInteractive"
        id="cookieWow"
        type="text/javascript"
        src={`https://cookiecdn.com/configs/${env.COOKIE_BANNER_ID}`}
        data-cwcid={env.COOKIE_BANNER_ID}
        defer={false}
      />
    </Fragment>
  )
}
