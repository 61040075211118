const colors = {
  primary: {
    10: '#ECF5FF',
    25: '#EDF6FF',
    50: '#C2E2FF',
    100: '#A3D3FF',
    200: '#85C4FF',
    300: '#66B3FF',
    400: '#469EFF',
    500: '#1F96FF',
    600: '#0083F5',
    700: '#0070CC',
    800: '#005AA3',
    900: '#00457A',
    1000: '#002E52',
  },
  secondary: {
    50: '#f0f3f6',
    100: '#d3dce5',
    200: '#b6c4d4',
    300: '#99adc3',
    400: '#7c95b2',
    500: '#5f7ea0',
    600: '#4d6783',
    650: '#466385',
    700: '#3c5066',
    800: '#314153',
    900: '#2b3949',
    950: '#263444',
    1000: '#1a222c',
  },
  tertiary: {
    50: '#eafeff',
    100: '#bffbff',
    200: '#95f9ff',
    300: '#6af6ff',
    400: '#36F3FF',
    500: '#00dcea',
    600: '#00b4bf',
    700: '#008c95',
    800: '#00646a',
    900: '#003c40',
    1000: '#001415',
  },
  gray: {
    0: '#ffffff',
    25: '#f4f4f4',
    50: '#f8f8f8',
    100: '#f6f6f6',
    200: '#ebebeb',
    300: '#e8e8e8',
    310: '#e6e6e6',
    380: '#c1c1c1',
    400: '#b2b2b2',
    500: '#a3a3a3',
    600: '#888888',
    680: '#7f7f7f',
    700: '#474747',
    800: '#3d3d3d',
    900: '#2b2b2b',
    920: '#252525',
    950: '#1a1a1a',
    1000: '#000000',
  },
  green: {
    450: '#6AC761',
  },
}

export default colors
