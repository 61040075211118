// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function checkNotEmpty(value: any): boolean {
  if (value == null) {
    return false
  }

  if (value && Array.isArray(value)) {
    return Boolean(value.length)
  }

  if (value && typeof value === 'object') {
    return Boolean(Object.keys(value).length)
  }

  if (typeof value === 'boolean') {
    return true
  }

  if (typeof value === 'number') {
    return true
  }

  return Boolean(value)
}
