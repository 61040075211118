import { forwardRef, ForwardedRef, useImperativeHandle } from 'react'
import NextLink from 'next/link'
import { Link as ChakraLink, CSSObject, As } from '@chakra-ui/react'
import { Link as ReactScrollLink } from 'react-scroll'
import { useRouter } from 'next/router'

export type ScrollOption = {
  spy?: boolean
  smooth?: boolean
  hashSpy?: boolean
  duration?: number
  offset?: number
  activeClass?: string
}

export type URL = {
  pathname: string
  hash?: string
  query?: Dict
}

export type ScrollLinkProps = {
  url: URL
  sx?: CSSObject
  children?: React.ReactNode
  as?: As
  onClick?: () => void
  scrollOption?: ScrollOption
}

export const SCROLL_OPTION: ScrollOption = {
  spy: false,
  smooth: true,
  hashSpy: true,
  duration: 500,
  offset: -60,
}

function ScrollLink(
  { url, sx, as, scrollOption, ...rest }: ScrollLinkProps,
  ref: ForwardedRef<unknown>,
): React.ReactElement {
  useImperativeHandle(ref, () => ({}))

  const { pathname } = useRouter()

  if (
    (url.pathname === pathname || url.pathname === 'currentPath') &&
    url.hash
  ) {
    return (
      <ReactScrollLink
        {...SCROLL_OPTION}
        {...scrollOption}
        {...rest}
        to={url.hash}
        href={url.hash === 'hero' ? '' : `#${url.hash}`}
      />
    )
  }

  return (
    <NextLink href={url} scroll passHref>
      <ChakraLink as={as} sx={sx} {...rest} />
    </NextLink>
  )
}

export default forwardRef(ScrollLink)
