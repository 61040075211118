const baseStyle = {
  fontSize: 'md',
  marginEnd: 3,
  mb: 4,
  fontWeight: 'medium',
  transition: 'all 0.2s',
  opacity: 1,
  _disabled: {
    opacity: 0.4,
  },
}

export default {
  baseStyle,
}
