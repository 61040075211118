import { extendTheme } from '@chakra-ui/react'

import layerStyles from './styles/layerStyles'
import textStyles from './styles/textStyles'
import colors from './styles/colors'
import style from './styles/style'
import components from './components'

const theme = {
  styles: {
    global: () => ({
      'html, body': {
        fontWeight: 'normal',
        color: 'gray.900',
      },
    }),
  },
  colors,
  layerStyles,
  textStyles,
  components,
  ...style,
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 300,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
}

export const defaultTheme = extendTheme(theme)

export const jpTheme = extendTheme({
  ...theme,
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
})

type _Theme = typeof theme

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends _Theme {}
}

export default defaultTheme
