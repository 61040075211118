import axios from 'axios'

function init(baseURL: string): void {
  Object.assign(axios.defaults, {
    baseURL: `${baseURL}`,
    timeout: 0,
    headers: {
      Accept: 'application/json',
      'Accept-Language': 'en',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      // "Access-Control-Allow-Origin": "*",
    },
  })
}

export default init
