const baseStyle = {
  transition: `all 0.15s ease-out`,
  cursor: 'pointer',
  textDecoration: 'none',
  outline: 'none',
  color: 'inherit',
  whiteSpace: 'nowrap',
  _hover: {
    textDecoration: 'none',
  },
  _focus: {
    boxShadow: 'none',
  },
}

const variants = {
  regular: {
    '&:hover': {
      color: 'primary.600',
    },
  },
  unstyled: {
    textDecoration: 'none !important',
  },
}

const defaultProps = {
  variant: 'regular',
}

export default {
  baseStyle,
  variants,
  defaultProps,
}
