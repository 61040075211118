const textStyles = {
  landingSectionTitle: {
    fontSize: {
      base: '2xl',
      sm: '4xl',
    },
    fontWeight: 'medium',
    textAlign: 'center',
  },
  landingSectionSubtitle: {
    fontSize: {
      base: 'lg',
      sm: '2xl',
    },
    textAlign: 'center',
  },
}

export default textStyles
